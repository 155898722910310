<div class="container">
    <div class="navegation-header d-flex align-items-center">
        <div class="box-icon" (click)="goBack()">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </div>
        <div class="box-title">
          <h3 style="margin-bottom: 0px;">Fidelidade</h3>
        </div>
    </div>
</div>
<div class="box-fidelity" *ngIf="this.fidelityGet">


  
  <div class="fidelity-option" *ngIf="this.fidelityUser.fidelityType == 0">
    <div class="progress-bar-fidelity">
      <span>0</span>
      <div class="progress" style="width: 100%;" role="progressbar" aria-label="Animated striped example"  [attr.aria-valuenow]="this.fidelityUser.ordersValue" [attr.aria-valuemin]="0" [attr.aria-valuemax]="fidelityUser.fidelityValue">
        <div class="progress-bar progress-bar-striped progress-bar-animated" style="background-color:  var(--primary-color);" [style.width.%]="this.percentageValue"></div>
      </div>
      <span>{{ fidelityUser.fidelityCount }}</span>
    </div>
  </div>

  <div class="fidelity-option" *ngIf="this.fidelityUser.fidelityType == 1">
    <div class="progress-span">
      <span>{{ this.fidelityUser.ordersCount }} / {{ this.fidelityUser.fidelityCount }}</span>
    </div>
    <div class="progress-bar-fidelity">
      <span>0</span>
      <div class="progress" style="width: 100%;" role="progressbar" aria-label="Animated striped example"  [attr.aria-valuenow]="this.fidelityUser.ordersCount" [attr.aria-valuemin]="0" [attr.aria-valuemax]="fidelityUser.fidelityCount">
        <div class="progress-bar progress-bar-striped progress-bar-animated" style="background-color:  var(--primary-color);" [style.width.%]="this.percentageCount"></div>
      </div>
      <span>{{ fidelityUser.fidelityCount }}</span>
    </div>
  </div>

  <div class="button-fidelity">
    <button>Usar</button>
  </div>
</div>