import { Injectable } from '@angular/core';
import { CacheService } from '../Cache/cache.service';
import { ApiService } from '../API/api.service';
import { BehaviorSubject, Observable } from 'rxjs';


export interface Usuario{
  response: boxClient
}
export interface boxClient{
  Customer: Cliente
  customer: Cliente
}
export interface Address{
  country: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  postalCode: string;
  number: string;
  complement: string;
  reference: string;
  identifier?: string;
  id_address: string;
  formattedaddress?: string;
  deliveryPrice: number;
  deliverydistance: number;
}
export interface Cliente{
  document: string;
  name: string;
  phone: string;
  address?: Address[];
  customerAddress?: Address;
  id_customer: string;
  totalOrders?: number;
}

export interface Fidelity{
  fidelity: boolean;
  fidelityType: number;
  fidelityCount: number;
  fidelityValue: number;
  fidelityApproved: boolean;
  bonusPercent: number;
  bonusValue: number;
  ordersValue: number;
  ordersCount: number
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

 usuario!: Cliente

 userSubject: BehaviorSubject<Cliente> = new BehaviorSubject<Cliente>(this.usuario);
 user$: Observable<Cliente> = this.userSubject.asObservable();

 editingUserSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
 editingUser$: Observable<boolean> = this.editingUserSubject.asObservable();

  constructor(private cacheService: CacheService) { }


  Usuario(){
    let user = this.cacheService.getItemLocal('user_key')
  }


}
