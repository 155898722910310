import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { CacheService } from 'src/app/shared/services/Cache/cache.service';
import { Company, ConnectionService } from 'src/app/shared/services/Connection/connection.service';
import { Cliente, Fidelity } from 'src/app/shared/services/User/user.service';

@Component({
  selector: 'app-fidelity',
  templateUrl: './fidelity.component.html',
  styleUrl: './fidelity.component.css'
})
export class FidelityComponent implements OnInit{

  nameCompany: string = '';
  user!: Cliente;
  company!: Company;
  fidelityUser!: Fidelity;
  fidelityGet: boolean = false;
  percentageValue: number = 0;
  percentageCount: number = 0;
  constructor(private router: Router, private connection: ConnectionService, private cacheService: CacheService, private apiService: ApiService){}

  ngOnInit(): void {
    this.connection.nameCompany$.subscribe((newNameCompany) => {
      this.nameCompany = newNameCompany
    })
    this.connection.company$.subscribe((newCompany) =>{
      this.company = newCompany
      this.getFidelity();
    })
    this.connection.user$.subscribe((User) =>{
      this.user = User;
      this.getFidelity();
    },
    () =>{
      if(this.cacheService.getItemLocal('user_key'))
      {
        this.user = this.cacheService.getItemLocal('user_key')
      }
    })
  }

  goBack(){
    this.router.navigate([this.nameCompany, 'user']);
  }

  //tipo fidelidade 1 é por pedido
  //tipo fidelidade 0 é por valor
  getFidelity(){
    if(this.company){
      if(this.user){
        if(!this.fidelityGet)
        {
          this.apiService.getFidelity(this.user.id_customer, this.company.company.id_company).subscribe(
            (data) =>{
              this.fidelityUser = data;
              this.fidelityGet = true;
              if(this.fidelityUser.fidelityType == 1){
                this.calcPercentageCount();
              }
              else{
                this.calcPercentageValue();
              }
              console.log(this.fidelityUser);
            },
            (error) =>{
              console.log(error);
            }
          )
        }
      }
    }

  }


  calcPercentageCount(){
    this.percentageCount = (this.fidelityUser.ordersCount / this.fidelityUser.fidelityCount) * 100;
    console.log(this.percentageCount)
  }

  calcPercentageValue(){
    this.percentageValue = (this.fidelityUser.ordersValue / this.fidelityUser.fidelityValue) * 100;
  }
  
}
