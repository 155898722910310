import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { TopMenuComponent } from '../top-menu/top-menu.component';
import { SideBarComponent } from '../side-bar/side-bar.component';
import { SplashComponent } from '../splash/splash.component';
import { BottomMenuComponent } from '../bottom-menu/bottom-menu.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { MenuComponent } from 'src/app/views/menu/menu.component';
import { ProductDetailComponent } from 'src/app/views/product-detail/product-detail.component';
import { TopMenuTwoComponent } from '../top-menu-two/top-menu-two.component';
import { BottomMenuConsumptionComponent } from '../bottom-menu-consumption/bottom-menu-consumption.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { EditProductComponent } from 'src/app/views/edit-product/edit-product.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { TopBarComponent } from '../top-bar/top-bar.component';
import { TopHeaderComponent } from '../top-header/top-header.component';
import { FormChangeComponent } from '../form-change/form-change.component';
import { ReceiptCardComponent } from '../receipt-card/receipt-card.component';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { CheckboxRadiusComponent } from '../checkbox-radius/checkbox-radius.component';
import { MethodCardComponent } from '../method-card/method-card.component';
import { PaymentFormCardComponent } from '../payment-form-card/payment-form-card.component';
import { AddressCardComponent } from '../address-card/address-card.component';
import { CardLoadingComponent } from '../CardLoading/card-loading/card-loading.component';
import { CopyButtonComponent } from '../copy-button/copy-button.component';
import { InputCopyPixComponent } from '../input-copy-pix/input-copy-pix.component';
import { CounterTimingComponent } from '../counter-timing/counter-timing.component';
import { CupomComponent } from '../cupom/cupom.component';
import { ObservationCardComponent } from '../observation-card/observation-card.component';
import { TimeAwaitComponent } from '../time-await/time-await.component';
import { ModalPromotionComponent } from '../modal-promotion/modal-promotion.component';
import { FormatationCurrencyDirective } from '../../directives/formatationCurrency.directive';



@NgModule({
  declarations: [
    AuthLayoutComponent,
    TopMenuComponent,
    BottomMenuComponent,
    SplashComponent,
    TopMenuTwoComponent,
    BottomMenuConsumptionComponent,
    FormChangeComponent,
    ReceiptCardComponent,
    CheckboxRadiusComponent,
    MethodCardComponent,
    PaymentFormCardComponent,
    AddressCardComponent,
    CardLoadingComponent,
    CopyButtonComponent,
    InputCopyPixComponent,
    CounterTimingComponent,
    CupomComponent,
    ObservationCardComponent,
    TimeAwaitComponent,
    ModalPromotionComponent,
    FormatationCurrencyDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgScrollbarModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule
  ],
  exports: [
    TopMenuComponent,
    BottomMenuComponent,
    TopMenuTwoComponent,
    BottomMenuConsumptionComponent,
    FormChangeComponent,
    ReceiptCardComponent,
    MethodCardComponent,
    PaymentFormCardComponent,
    AddressCardComponent,
    CardLoadingComponent,
    CopyButtonComponent,
    InputCopyPixComponent,
    CounterTimingComponent,
    CheckboxRadiusComponent,
    CupomComponent,
    ObservationCardComponent,
    TimeAwaitComponent,
    ModalPromotionComponent
  ]
})
export class LayoutModule { }
